/**
 * Decode JW Token to retrieve useful information.
 *
 * @param {String} token The token string to parse.
 * @returns {Object} The final parsed object containing the token's information.
 */
export default function decodeJWT(token) {
  try {
    const middlePart = token.split('.')[1].replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(middlePart));
  } catch (error) {
    return {};
  }
}
