// Core imports
import React from 'react';

// Material component imports
import Grid from '@material-ui/core/Grid';

// Custom component imports
import DocumentTitle from '../../components/UI/DocumentTitle/DocumentTitle';
import MainLayout from '../../components/Layout/MainLayout/MainLayout';

const RequestsHistoryScreen = () => {

  return (
    <MainLayout>
      <DocumentTitle title="My Requests" />

      <Grid container spacing={4} justify="center" alignContent="center" alignItems="center">
        <Grid item xs={12}>
          My requests!
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default RequestsHistoryScreen;
