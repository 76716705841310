// Core imports
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import ReactWebChat from 'botframework-webchat';
import { hooks } from 'botframework-webchat-component';

// Misc imports
import { BOT_STYLE, useBotStyles } from '../../../constants/botStyle';
import is from '../../../utils/is';

const Webchat = ({ directLine, store }) => {
  const botClasses = useBotStyles();
  const userId = localStorage.getItem('userId') || null;

  const { useSendPostBack, useActivities } = hooks;
  const sendPostBack = useSendPostBack();
  const [activities] = useActivities();

  const location = useLocation();
  const startNode = new URLSearchParams(location.search).get('hcn');

  const hasClass = (elem, className) => {
    return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ');
  };

  const addClass = (elem, className) => {
    if (!hasClass(elem, className)) {
      elem.className += ' ' + className;
    }
  };

  const removeClass = (elem, className) => {
    var newClass = ' ' + elem.className.replace( /[\t\r\n]/g, ' ') + ' ';
    if (hasClass(elem, className)) {
      while (newClass.indexOf(' ' + className + ' ') >= 0 ) {
        newClass = newClass.replace(' ' + className + ' ', ' ');
      }
      elem.className = newClass.replace(/^\s+|\s+$/g, '');
    }
  };

  // On mount
  useEffect(() => {
    // If the user has specified a start node, then start from there
    if (startNode) {
      setTimeout(() => {
        sendPostBack({
          postback_id: startNode
        });
      }, 1000);
    } else {
    // If it's the first time the user sees the bot, send a welcome message
      const lastActivities = localStorage.getItem('lastActivities') && JSON.parse(localStorage.getItem('lastActivities'));
      if (!lastActivities || is.emptyArray(lastActivities)) {
        setTimeout(() => {
          sendPostBack({
            postback_id: 'welcome-node'
          });
        }, 1000);
      }
    }
  }, []); // eslint-disable-line

  // When there is a new activity
  useEffect(() => {
    // Keep the last 10 activities in the local storage
    localStorage.setItem('lastActivities', JSON.stringify(activities.slice(Math.max(activities.length - 10, 0))));

    // Handle submit button disable for adaptive cards
    const buttons = document.getElementsByClassName('ac-pushButton');
    if (buttons.length > 0) {
      const button = buttons[buttons.length - 1];
      button.addEventListener('click', function() {
        button.disabled = true;
      });
    }

    // If the latest activity contains suggested actions, disable the text input
    const lastActivity = activities && activities.length > 0 && activities[activities.length - 1];
    const suggestedActions = lastActivity && lastActivity.suggestedActions;

    const input = document.querySelector(`.webchat__send-box-text-box__input`);
    const uploadButton = document.querySelectorAll(`.webchat__icon-button`)[0];
    const sendButton = document.querySelectorAll(`.webchat__icon-button`)[1];

    console.log(uploadButton);

    if (input && sendButton) {

      if (suggestedActions) {
        input.disabled = true;
        input.placeholder = 'Please select one of the buttons above';
        sendButton.disabled = true;

        if (uploadButton) {
          uploadButton.disabled = true;
          addClass(uploadButton, 'disabled');
        }
      } else {
        input.disabled = false;
        input.placeholder = 'Type your message';
        sendButton.disabled = false;

        if (uploadButton) {
          uploadButton.disabled = false;
          removeClass(uploadButton, 'disabled');
        }
      }
    }
  }, [activities.length]); // eslint-disable-line

  return (
    <div className={botClasses.botContainer}>
      <ReactWebChat
        directLine={directLine}
        store={store}
        styleOptions={BOT_STYLE}
        userID={userId}
      />
    </div>
  );
};

Webchat.propTypes = {
  directLine: PropTypes.object,
  store: PropTypes.object
};

export default Webchat;
