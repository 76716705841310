
import MuiAppBar from './MuiAppBar';
import MuiButton from './MuiButton';
import MuiLink from './MuiLink';
import MuiPaper from './MuiPaper';

export default {
  MuiAppBar,
  MuiButton,
  MuiLink,
  MuiPaper
};
