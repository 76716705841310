// Core imports
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hooks } from 'botframework-webchat-component';

// Material component imports
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

// Custom component imports
import StatusItem from './StatusItem';

// Misc imports
import { getSubscriberStatus } from '../../../store/webchat/webchatThunks';
import is from '../../../utils/is';
import { useUtilStyles } from '../../../theme/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: 0,
      paddingTop: 20,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0
    }
  })
);

const StatusTimeline = props => {
  const classes = useStyles();
  const utilClasses = useUtilStyles();
  const dispatch = useDispatch();

  const { useActivities } = hooks;
  const [activities] = useActivities();

  const { subscriberStatus } = useSelector((state) => state.webchat);
  const { blackboard } = subscriberStatus || {};
  const { data: subscriberData } = blackboard || {};
  const interviewStatus = (subscriberData || {})['interview-status'];
  const { currentStep = 1, labels = {} } = interviewStatus || {};

  // When there's a new activity
  useEffect(() => {
    dispatch(getSubscriberStatus());
  }, [activities.length]); // eslint-disable-line

  return (
    <Timeline className={classes.root}>
      <StatusItem
        stepNumber={1}
        isCurrent={currentStep === 1}
        isCompleted={currentStep > 1}
        stepTitle="What you need"
        stepSubtitle={
          is.array(labels[1]) && is.not.emptyArray(labels[1]) && labels[1].map((item, index) => {
            return (
              <Fragment key={index}>
                <ArrowForwardIcon fontSize="inherit" />

                <span className={utilClasses.ml1}>
                  {item}
                </span>
              </Fragment>
            );
          })
        }
      />

      <StatusItem
        stepNumber={2}
        isCurrent={currentStep === 2}
        isCompleted={currentStep > 2}
        stepTitle="Project info"
        stepSubtitle={
          is.array(labels[2]) && is.not.emptyArray(labels[2]) && labels[2].map((item, index) => {
            return (
              <Fragment key={index}>
                <ArrowForwardIcon fontSize="inherit" />

                <span className={utilClasses.ml1}>
                  {item}
                </span>
              </Fragment>
            );
          })
        }
      />

      <StatusItem
        stepNumber={3}
        isCurrent={currentStep === 3}
        isCompleted={currentStep > 3}
        isFinalStep
        stepTitle="Proceed to order"
        stepSubtitle={
          is.array(labels[3]) && is.not.emptyArray(labels[3]) && labels[3].map((item, index) => {
            return (
              <Fragment key={index}>
                <ArrowForwardIcon fontSize="inherit" />

                <span className={utilClasses.ml1}>
                  {item}
                </span>
              </Fragment>
            );
          })
        }
      />
    </Timeline>
  );
};

export default StatusTimeline;
