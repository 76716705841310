// Core imports
import React from 'react';
import PropTypes from 'prop-types';
import { hooks } from 'botframework-webchat-component';
import { useLocation } from 'react-router-dom';

// Material component imports
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fontSize: 12,
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      padding: '2px 10px',
      textTransform: 'none'
    },
    icon: {
      fontSize: '14px !important',
      marginRight: '-4px'
    }
  })
);

const StartOverButton = ({ variant }) => {
  const { useSendPostBack } = hooks;
  const classes = useStyles();
  const sendPostBack = useSendPostBack();

  const location = useLocation();
  const startNode = new URLSearchParams(location.search).get('hcn');

  const onTrigger = () => {
    sendPostBack({
      postback_id: startNode || 'intro-node'
    });
  };

  return (
    <Button
      size="small"
      variant={variant}
      color="inherit"
      startIcon={
        <RefreshIcon className={classes.icon} />
      }
      onClick={onTrigger}
      className={classes.root}
    >
      Start over
    </Button>
  );
};

StartOverButton.defaultProps = {
  variant: 'contained'
};

StartOverButton.propTypes = {
  variant: PropTypes.oneOf(['contained', 'outlined', 'text'])
};

export default StartOverButton;
