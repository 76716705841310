import { webchatActions } from './webchat';
import { SnackbarManager } from '../../components';
import { API_BASE_URL, CORE_MOD_TOKEN, DIRECT_LINE_TOKEN_URL } from '../../constants/constants';

export const getDirectLineToken = (
  throwErrors = false
) => async dispatch => {
  dispatch(webchatActions.directLineTokenFetchStart());

  try {
    const res = await fetch(DIRECT_LINE_TOKEN_URL, { method: 'GET' });

    const { token } = await res.json();

    localStorage.setItem('directLineToken', token);

    dispatch(webchatActions.directLineTokenFetchSuccess(token));

    return token;
  } catch (error) {
    dispatch(webchatActions.directLineTokenFetchFail(error));
    SnackbarManager.error('Error connecting to the chatbot. Please try again.');

    if (throwErrors) {
      throw error;
    }
  }
};

export const getSubscriberStatus = (
  throwErrors = false
) => async dispatch => {
  dispatch(webchatActions.subscriberStatusFetchStart());

  try {
    const userId = localStorage.getItem('userId');

    if (!userId) {
      throw new Error('No user found.');
    }

    const res = await fetch(`${API_BASE_URL}/moderator/subscribers/handle/${userId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${CORE_MOD_TOKEN}`
      }
    });

    const subscriberStatus = await res.json();

    dispatch(webchatActions.subscriberStatusFetchSuccess(subscriberStatus));

    return subscriberStatus;
  } catch (error) {
    dispatch(webchatActions.subscriberStatusFetchFail(error));
    SnackbarManager.error('Error fetching progress status.');

    if (throwErrors) {
      throw error;
    }
  }
};

// Clean up the local store & reset the redux state
export const clearWebchatState = () => async dispatch => {
  localStorage.removeItem('directLineToken');
  localStorage.removeItem('lastActivities');
  dispatch(webchatActions.reset());
};
