import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { NAV_ROUTES } from '../constants/constants';

import HomeScreen from '../screens/HomeScreen/HomeScreen';
import BotScreen from '../screens/BotScreen/BotScreen';
import ResourcesScreen from '../screens/ResourcesScreen/ResourcesScreen';
import RequestsHistoryScreen from '../screens/RequestsHistoryScreen/RequestsHistoryScreen';
import PageNotFoundScreen from '../screens/PageNotFoundScreen';

const Root = () => {
  return (
    <Switch>
      <Route
        exact
        path={NAV_ROUTES.ROOT}
      >
        <HomeScreen />
      </Route>

      <Route
        exact
        path={NAV_ROUTES.BOT_PLAIN}
      >
        <BotScreen />
      </Route>

      <Route
        exact
        path={NAV_ROUTES.RESOURCES}
      >
        <ResourcesScreen />
      </Route>

      <Route
        exact
        path={NAV_ROUTES.REQUESTS_HISTORY}
      >
        <RequestsHistoryScreen />
      </Route>

      <Route>
        <PageNotFoundScreen />
      </Route>
    </Switch>
  );
};

export default Root;
