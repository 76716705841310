// Core imports
import React from 'react';
import PropTypes from 'prop-types';

// Material component imports
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';

import CheckIcon from '@material-ui/icons/Check';

// Misc imports
import { useUtilStyles } from '../../../theme/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    timelineItem: {
      minHeight: 80,
      paddingLeft: 24,
      paddingRight: 10,

      '&::before': {
        flex: 0,
        padding: 0
      }
    },
    timelineDot: {
      height: 40,
      width: 40,
      boxShadow: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 28,
      fontWeight: 600,
      color: '#989799',
      backgroundColor: '#dddcdd',

      '&.current': {
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.light
      }
    },
    timelineConnector: {
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderStyle: 'dashed',
      borderColor: '#dddcdd'
    },
    timelineContent: {
      paddingTop: 18,
      color: theme.palette.primary.light,
      fontSize: 16,
      fontWeight: 400,

      '&.has-subtitle': {
        paddingTop: 12
      }
    },
    timelineContentTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    timelineContentSubtitle: {
      color: '#a2a4ab',

      fontSize: 13,
      display: 'flex',
      alignItems: 'center',

      '&.completed': {
        color: theme.palette.grey
      }
    }
  })
);

const StatusItem = ({ stepNumber, stepTitle, stepSubtitle, isCurrent, isCompleted, isFinalStep }) => {
  const classes = useStyles();
  const utilClasses = useUtilStyles();

  return (
    <TimelineItem className={classes.timelineItem}>

      <TimelineSeparator>
        <TimelineDot className={`${classes.timelineDot} ${isCurrent && 'current'}`}>
          {stepNumber}
        </TimelineDot>

        {
          !isFinalStep ? (
            <TimelineConnector className={classes.timelineConnector} />
          ) : null
        }
      </TimelineSeparator>

      <TimelineContent className={`${classes.timelineContent} ${isCurrent && 'current'} ${isCompleted && 'completed'} ${stepSubtitle && 'has-subtitle'}`}>
        <Typography className={classes.timelineContentTitle}>
          {stepTitle}

          {
            isCompleted ? (
              <CheckIcon className={utilClasses.ml1} fontSize="small" color="primary" />
            ) : null
          }
        </Typography>

        {
          stepSubtitle ? (
            <Typography className={`${classes.timelineContentSubtitle} ${isCurrent && 'current'} ${isCompleted && 'completed'}`}>
              {stepSubtitle}
            </Typography>
          ) : null
        }

      </TimelineContent>

    </TimelineItem>
  );
};

StatusItem.propTypes = {
  isFinalStep: PropTypes.bool,
  isCurrent: PropTypes.bool,
  isCompleted: PropTypes.bool,
  stepNumber: PropTypes.number,
  stepTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  stepSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default StatusItem;
