// Core imports
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Material component imports
import { makeStyles, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';

// Misc imports
import { NAV_ROUTES } from '../../../constants/constants';
import logo from '../../../assets/logo.svg';
import clientLogo from '../../../assets/client_logo.png';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: 'auto'
    },
    appLogo: {
      height: 42,
      verticalAlign: 'text-top'
    },
    grow: {
      flexGrow: 1
    },
    list: {
      fontSize: 14,
      margin: 0,
      paddingLeft: '0',
      listStyle: 'none',
      paddingTop: 0,
      paddingBottom: 0,
      color: 'inherit'
    },
    listItem: {
      float: 'left',
      color: '#95928f',
      position: 'relative',
      display: 'block',
      width: 'auto',
      margin: '0',
      padding: '0'
    },
    navLink: {
      color: 'inherit',
      padding: '0.5rem 0.7rem',
      fontSize: 15,
      fontWeight: 500,
      textTransform: 'none',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      borderRadius: 6,
      boxShadow: 'none',
      '&:hover,&:focus': {
        color: 'inherit',
        background: theme.palette.grey[100],
        boxShadow: 'none'
      }
    },
    navLinkActive: {
      color: 'inherit',
      backgroundColor: theme.palette.grey[100]
    },
    icons: {
      width: 18,
      height: 18,
      marginRight: 6
    }
  })
);

const Topbar = ({ hasMenu = true, onSidebarOpen }) => {
  const classes = useStyles();

  const renderLogo = () => (
    <RouterLink to={NAV_ROUTES.ROOT}>
      <img src={logo} className={classes.appLogo} alt="logo" />
    </RouterLink>
  );

  return (
    <AppBar color="inherit" position="relative">
      <Toolbar className={classes.root}>
        {hasMenu ? (
          <Fragment>
            {/* Mobile only */}
            <Hidden smUp>
              <Grid container justify="space-between" alignItems="center">
                {renderLogo()}

                <Grid item>
                  <IconButton color="inherit" onClick={onSidebarOpen}>
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Hidden>

            {/* Web only */}
            <Hidden xsDown>
              {renderLogo()}

              <Box marginLeft={3}>
                <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <Button
                      color="inherit"
                      component={RouterLink}
                      to={NAV_ROUTES.ROOT}
                      className={classes.navLink}
                    >
                      Talent Portal
                    </Button>
                  </ListItem>

                  <ListItem className={classes.listItem}>
                    <Button
                      color="inherit"
                      component={RouterLink}
                      to={NAV_ROUTES.ROOT}
                      className={classes.navLink}
                    >
                      Virtual Assistant
                    </Button>
                  </ListItem>

                  <ListItem className={classes.listItem}>
                    <Button
                      color="inherit"
                      component={RouterLink}
                      to={NAV_ROUTES.REQUESTS_HISTORY}
                      className={classes.navLink}
                    >
                      My Requests
                    </Button>
                  </ListItem>

                  <ListItem className={classes.listItem}>
                    <Button
                      color="inherit"
                      component={RouterLink}
                      to={NAV_ROUTES.RESOURCES}
                      className={classes.navLink}
                    >
                      Resources
                    </Button>
                  </ListItem>
                </List>

              </Box>

              <div className={classes.grow} />

              <img src={clientLogo} className={classes.appLogo} alt="client logo" />
            </Hidden>
          </Fragment>
        ) : (
          renderLogo()
        )}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  hasMenu: PropTypes.bool,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
