// Core imports
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

// Material component imports
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';

// Misc imports
import logo from '../../../assets/logo_white.svg';
import { NAV_ROUTES } from '../../../constants/constants';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    appLogo: {
      height: 42,
      verticalAlign: 'text-top',
      opacity: 0.35
    },
    grow: {
      flexGrow: 1
    }
  })
);

const Footer = props => {
  const classes = useStyles();

  const renderLogo = () => (
    <RouterLink to={NAV_ROUTES.ROOT}>
      <img src={logo} className={classes.appLogo} alt="logo" />
    </RouterLink>
  );

  return (
    <AppBar color="secondary" position="relative" >
      <Toolbar>
        {/* Mobile only */}
        <Hidden smUp>
          <Grid container justify="space-between" alignItems="center">
            {renderLogo()}
          </Grid>
        </Hidden>

        {/* Web only */}
        <Hidden xsDown>
          <Typography variant="subtitle2" color="textSecondary">
            Chatbot powered by Helvia
          </Typography>

          <div className={classes.grow} />

          <Box marginRight={4}>
            <Typography variant="subtitle2" color="textSecondary">
              Privacy Statement | Copyright 2020 Kelly Services Inc.
            </Typography>
          </Box>

          {renderLogo()}
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
