// Core imports
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createDirectLine, createStore } from 'botframework-webchat';
import { Components } from 'botframework-webchat-component';

// Material component imports
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Custom component imports
import DocumentTitle from '../../components/UI/DocumentTitle/DocumentTitle';

// Misc imports
import decodeJWT from '../../utils/misc/decodeJWT';
import { useUtilStyles } from '../../theme/styles';
import { getDirectLineToken, clearWebchatState } from '../../store/webchat/webchatThunks';
import Webchat from '../../components/UI/Webchat';
import StartOverButton from '../../components/UI/StartOverButton/StartOverButton';
import { BOT_ID, NUMBER_OF_HISTORY_MESSAGES, WEBCHAT_VERSION } from '../../constants/constants';

const HomeScreen = () => {
  const utilClasses = useUtilStyles();
  const dispatch = useDispatch();

  // Local state
  const [userId, setUserId] = useState(localStorage.getItem('userId'));

  // Redux store
  const { isFetchingDirectLineToken, directLineToken, fetchDirectLineTokenError } = useSelector((state) => state.webchat);

  // Webchat setup
  const directLine = useMemo(() => createDirectLine({ token: directLineToken }), [directLineToken]);
  const webchatStore = useMemo(() => createStore(
    {},
    () => {
      return next => {
        return action => {
          let event;

          switch (action.type) {
            case 'DIRECT_LINE/CONNECT_FULFILLED':
              event = new Event('onDirectLineConnectionFulfilled');
              event.directLine = action.payload.directLine;
              event.meta = action.meta;

              // Store the user ID
              localStorage.setItem('userId', action.meta.userID);
              setUserId(action.meta.userID);

              window.dispatchEvent(event);
              break;
            case 'DIRECT_LINE/DISCONNECT_PENDING':
              event = new Event('onDirectLineConnectionRejected');
              window.dispatchEvent(event);
              break;
            case 'DIRECT_LINE/INCOMING_ACTIVITY':
              if (action.payload.activity && action.payload.activity.type === 'message') {
                event = new Event('onDirectLineIncomingMessageActivity');
                const latestWatermark = parseInt(action.payload.activity.id.split('|').pop(), 10);
                event.watermark = latestWatermark > NUMBER_OF_HISTORY_MESSAGES ? latestWatermark - NUMBER_OF_HISTORY_MESSAGES : '';
                window.dispatchEvent(event);
              }
              break;
            case 'DIRECT_LINE/POST_ACTIVITY':
              if (action.payload.activity.channelData) {
                action.payload.activity.channelData.webchat_id = BOT_ID;
                action.payload.activity.channelData.fullURL = window.location.href;
                action.payload.activity.channelData.domainName = window.location.origin;
                action.payload.activity.channelData.version = WEBCHAT_VERSION;
              } else {
                action.payload.activity.channelData = {
                  webchat_id: BOT_ID,
                  fullURL: window.location.href,
                  domainName: window.location.origin,
                  version: WEBCHAT_VERSION
                };
              }
              break;
            default:
              break;
          }
          return next(action);
        };
      };
    }
  ), []);

  // On mount
  useEffect(() => {
    if (!directLineToken) {
      dispatch(getDirectLineToken());
    } else {
      // Check if the token is still valid, otherwise refresh it
      const decodedToken = decodeJWT(directLineToken);
      const tokenExpiration = decodedToken.exp * 1000;
      const isTokenExpired = tokenExpiration - Date.now() <= 0;

      if (isTokenExpired) {
        window.localStorage.removeItem('lastActivities');
        dispatch(getDirectLineToken());
      }
    }
  }, [dispatch]); // eslint-disable-line

  // On umnount
  React.useEffect(() => {
    return () => {
      dispatch(clearWebchatState());
    };
  }, [dispatch]);

  const renderWebchat = () => {
    if (isFetchingDirectLineToken) {
      return (
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <CircularProgress color="inherit" size={50} thickness={4} />
          </Grid>
        </Grid>
      );
    }

    if (fetchDirectLineTokenError) {
      return (
        <Typography>
          TODO
        </Typography>
      )
    }

    // else
    return (
      <Components.Composer directLine={directLine} store={webchatStore}>
        <Grid container spacing={4}>
          <Grid container spacing={4} item>

            <Grid item xs={12}>
              <Grid container justify="flex-end" alignItems="center">
                <Grid item>
                  {
                    directLine.token && webchatStore && userId && (
                      <Grid container item spacing={1}>
                        <Grid item>
                          <StartOverButton variant="outlined" />
                        </Grid>
                      </Grid>
                    )
                  }
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={utilClasses.mb3}>
              {
                directLine.token && webchatStore && userId && (
                  <Webchat directLine={directLine} store={webchatStore} />
                )
              }
            </Grid>

            <Grid item xs={12} className={`${utilClasses.mb3} ${utilClasses.textCenter}`}>
              <Typography variant="caption">
                Virtual Assistant powered by Helvia
              </Typography>
            </Grid>

          </Grid>
        </Grid>
      </Components.Composer>
    );
  };

  return (
    <Fragment>
      <DocumentTitle title="Home" />
      {renderWebchat()}
    </Fragment>
  );
};

export default HomeScreen;
