const { REACT_APP_API_BASE_URL, REACT_APP_DIRECT_LINE_TOKEN_URL, REACT_APP_BOT_ID, REACT_APP_CORE_MOD_TOKEN } = process.env;

// Navigation routes
export const NAV_ROUTES = {
  ROOT: '/',
  BOT_PLAIN: '/bot',
  RESOURCES: '/resources',
  REQUESTS_HISTORY: '/requests'
};

// Environment variables
export const API_BASE_URL = REACT_APP_API_BASE_URL || '';
export const DIRECT_LINE_TOKEN_URL = REACT_APP_DIRECT_LINE_TOKEN_URL || '';
export const CORE_MOD_TOKEN = REACT_APP_CORE_MOD_TOKEN || '';

// General settings
export const WEBPAGE_TITLE_DEFAULT = 'Kelly';

// Bot preferences
export const BOT_ID = REACT_APP_BOT_ID || '';
export const NUMBER_OF_HISTORY_MESSAGES = 10;
export const WEBCHAT_VERSION = `kelly_guided_work_${require('../../package.json').version}`;

// Regular expressions
export const URL_PARAM_REGEX = /\/(:)([a-zA-Z])\w+/;
