import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useLayoutStyles = makeStyles((theme) =>
  createStyles({
    marginHorizontal: {
      marginLeft: theme.spacing(25),
      marginRight: theme.spacing(25),

      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(10),
        marginRight: theme.spacing(10)
      },

      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      }
    },
    marginVertical: {
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(7),

      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(7),
        marginBottom: theme.spacing(7)
      },

      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
      }
    }
  })
);

export const useUtilStyles = makeStyles((theme) =>
  createStyles({
    // Padding utils
    px2: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    px4: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    },
    py2: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    p2: {
      padding: theme.spacing(2)
    },
    p4: {
      padding: theme.spacing(4)
    },

    // Margin utils
    mt1: {
      marginTop: theme.spacing(1)
    },
    mt3: {
      marginTop: theme.spacing(3)
    },
    mb2: {
      marginBottom: theme.spacing(2)
    },
    mb3: {
      marginBottom: theme.spacing(3)
    },
    ml1: {
      marginLeft: theme.spacing(1)
    },
    mr1: {
      marginRight: theme.spacing(1)
    },
    my1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },

    // Text utils
    textCenter: {
      textAlign: 'center'
    },
    textWhite: {
      color: theme.palette.white
    },
    fwSemiBold: {
      fontWeight: 600
    },
    fwBold: {
      fontWeight: 'bold'
    },

    // Image utils
    imgResponsive: {
      maxWidth: '100%',
      height: 'auto'
    },

    // Box shadow utils
    shadowDefault: {
      boxShadow: '3px 3px 28px -12px rgba(0,0,0,0.75)'
    }
  })
);
