export default {
  root: {
    borderRadius: '30px',
    padding: '12px 30px',
    margin: '.3125rem 1px',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '1.42857143'
  }
};
