
import { createStyles, makeStyles } from '@material-ui/core';
import theme from '../theme';

import TypingIndicator from '../assets/typing.svg';
import UserAvatar from '../assets/user.svg';
import BotAvatar from '../assets/kelly-hackathon-avatar.png';
import CheckIcon from '../assets/check.svg';

const PADDING_REGULAR = 8;
const PADDING_WIDE = 12;
export const BOT_HEIGHT = 540;

export const BOT_STYLE = {
  // Color and paddings
  accent: theme.palette.primary.main,
  backgroundColor: 'White',
  paddingRegular: PADDING_REGULAR,
  paddingWide: PADDING_WIDE,

  // Fonts
  primaryFont: 'TTNormsPro, Arial, sans-serif',

  // Avatar
  avatarBorderRadius: '50%',
  avatarSize: 38,
  botAvatarBackgroundColor: 'White', // defaults to accent color
  botAvatarImage: BotAvatar,
  botAvatarInitials: '',
  userAvatarBackgroundColor: 'White', // defaults to accent color
  userAvatarImage: UserAvatar,
  userAvatarInitials: '',

  // Bubble
  bubbleBackground: theme.palette.primary.main,
  bubbleBorderColor: '#f9f8f9',
  bubbleBorderRadius: 6,
  bubbleBorderStyle: 'solid',
  bubbleBorderWidth: 1,
  bubbleTextColor: 'White',
  bubbleNubOffset: 16,
  bubbleNubSize: 12,

  bubbleFromUserBackground: '#dddcdd',
  bubbleFromUserBorderColor: '#dddcdd',
  bubbleFromUserBorderRadius: 6,
  bubbleFromUserBorderStyle: 'solid',
  bubbleFromUserBorderWidth: 1,
  bubbleFromUserNubOffset: 16,
  bubbleFromUserNubSize: 12,

  // Root
  //rootHeight: BOT_HEIGHT,

  // Send box
  hideUploadButton: false,

  // Suggested actions
  suggestedActionBackground: '#dddcdd',
  suggestedActionBorderColor: '#dddcdd', // defaults to accent
  suggestedActionBorderRadius: 4,
  suggestedActionBorderStyle: 'solid',
  suggestedActionBorderWidth: 0,
  suggestedActionHeight: 28,
  suggestedActionLayout: 'stacked', // either "carousel" or "stacked"
  suggestedActionTextColor: theme.palette.black, // can be DEFAULT_ACCENT

  // Typing indicator
  typingAnimationBackgroundImage: `url(${TypingIndicator})`,
  typingAnimationDuration: 0,
  typingAnimationHeight: 35,
  typingAnimationWidth: 53
};

// Idiosyncratic styling overrides (note: avoid whenever possible)
// https://github.com/microsoft/BotFramework-WebChat/tree/master/samples/02.branding-styling-and-customization/a.branding-web-chat
export const useBotStyles = makeStyles((theme) =>
  createStyles({
    botContainer:{
      boxShadow: '0px 5px 30px -12px rgba(0,0,0,0.55)',
      background: theme.palette.white,
      minHeight: 341,
      maxHeight: BOT_HEIGHT,
      height: '100%',

      '& .main': {
        '& .webchat__send-box-text-box__input': {
          '&:disabled': {
            '&::placeholder': {
              color: 'rgba(0, 0, 0, 0.25)'
            }
          }
        },

        '& .disabled': {
          pointerEvents: 'none'
        }
        // marginTop: 16,
        // borderRadius: 8,
        // boxShadow: '3px 12px 28px -12px rgba(0,0,0,0.75)'
      },

      '& .webchat': {
        '&__connectivityStatus': {
          //background: theme.palette.white,
          margin: 0,
          padding: PADDING_REGULAR
        },

        '&__stacked-layout': {
          '&__message-row': {
            paddingLeft: PADDING_REGULAR,
            paddingRight: PADDING_REGULAR
          },

          // Hack to hide timestamps
          '&__status': {
            display: 'none !important'
          },

          '&__attachment': {
            marginLeft: 16,
            marginRight: 16
          }
        },

        '&__basic-transcript': {
          paddingTop: PADDING_REGULAR,
          paddingLeft: PADDING_REGULAR,
          paddingRight: PADDING_REGULAR,
          //backgroundColor: theme.palette.white
        },

        '&__bubble': {
          marginLeft: PADDING_REGULAR,
          marginRight: PADDING_REGULAR,
          animationDuration: '0.15s',
          animationFillMode: 'both',
          animationName: 'zoomIn',
          transformOrigin: 'bottom left',

          // Hack to remove fillers
          maxWidth: 'none !important',

          '&__content': {
            paddingLeft: PADDING_REGULAR,
            paddingRight: PADDING_REGULAR,
            transformOrigin: 'bottom right',

            '& a': {
              fontWeight: 600,
              color: theme.palette.white
            }
          },

          '&--from-user': {
            transformOrigin: 'bottom right',
            marginTop: PADDING_WIDE,
            marginBottom: PADDING_WIDE
          }
        },

        '&__defaultAvatar': {
          '&--fromUser': {
            marginTop: PADDING_WIDE,
            marginBottom: PADDING_WIDE
          }
        },

        '&__typingIndicator': {
          animationDuration: '0.15s',
          animationFillMode: 'both',
          animationName: 'zoomIn',
          transformOrigin: 'bottom left'
        },

        '&__suggested-actions': {
          //backgroundColor: theme.palette.white,
          paddingLeft: 70,
          paddingRight: PADDING_REGULAR,
          paddingBottom: 6
        },

        '&__suggested-action': {
          display: 'block !important',

          '&__button': {
            justifyContent: 'left !important',
            overflow: 'visible',
            transition: 'all 0.3s ease-in-out',

            '&:hover': {
              cursor: 'pointer',
              backgroundColor: `${theme.palette.grey[400]} !important`
            },

            '&:focus': {
              cursor: 'pointer',
              backgroundColor: `${theme.palette.grey[500]} !important`,
              outline: 'none'
            },

            '&-text': {
              fontWeight: 400,
              width: '100%',
              whiteSpace: 'pre-wrap',
              textAlign: 'left'
            }
          }
        },

        // Adaptive cards
        '&__adaptive-card-renderer': {
          '& .ac-choiceSetInput-expanded > div': {
            marginBottom: 4
          },

          // Text inputs
          '& .ac-textInput': {
            borderColor: theme.palette.primary.main,
            borderWidth: 1,
            borderStyle: 'solid',
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 12,
            paddingBottom: 12,
            marginBottom: 6,
            borderRadius: 5,
            fontSize: 14,
            lineHeight: 1.5,
            transition: 'all 0.3s ease-in-out',

            '&:focus': {
              outline: 'none',
              borderColor: theme.palette.primary.dark,
              boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.1)'
            }
          },

          // Text areas
          '& .ac-multiline': {
            height: 80,
            resize: 'none',
          },

          // Select inputs
          '& .ac-multichoiceInput': {
            borderColor: theme.palette.primary.main,
            borderWidth: 1,
            borderStyle: 'solid',
            marginBottom: 6,
            borderRadius: 5,
            fontSize: 14,
            lineHeight: 1.5,
            paddingTop: '13px !important',
            paddingBottom: '13px !important',
            paddingLeft: '12px !important',
            transition: 'all 0.3s ease-in-out',

            '&:focus': {
              outline: 'none',
              borderColor: theme.palette.primary.dark,
              boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.1)'
            }
          },

          // Radio buttons
          '& input[type="radio"]': {
            outline: 0,
            cursor: 'pointer',

            '&:before': {
              position: 'relative',
              display: 'block',
              top: -1,
              left: -1,
              width: 16,
              height: 16,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: theme.palette.primary.main,
              content: '"" !important',
              background: theme.palette.white,
              borderRadius: '100%',
              transition: 'all 250ms ease'
            },

            '&:after': {
              position: 'relative',
              display: 'block',
              left: -1,
              top: -17,
              width: 16,
              height: 16,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: theme.palette.primary.main,
              content: '"" !important',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: '100%',
              transition: 'all 250ms ease'
            },

            '&:checked': {
              '&:after': {
                backgroundImage: `url(${CheckIcon})`
              }
            },

            '&:disabled': {
              cursor: 'not-allowed',
              opacity: 0.5
            }
          },

          '& .ac-actionSet': {
            '& button': {
              fontWeight: 400,
              color: theme.palette.secondary.main,
              border: '1px solid transparent',
              fontSize: 15,
              lineHeight: 1.2,
              borderRadius: 5,
              transition: 'all 0.3s ease-in-out',
              outline: 'none',

              '&:hover': {
                backgroundColor: '#eafff2',
                cursor: 'pointer'
              },

              '&:disabled': {
                opacity: 0.5,
                cursor: 'not-allowed',

                '&:hover': {
                  backgroundColor: theme.palette.white
                },
              }
            }
          }
        }
      }
    }
  })
);
