// Core imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material component imports
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

// Custom component imports
import FullPageSpinner from '../../UI/FullPageSpinner';
import Sidebar from '../Sidebar';
import Topbar from '../Topbar';
import Footer from '../Footer';

// Misc imports
import { IsDesktopScreenSize } from '../../../utils/platform';
import { useLayoutStyles } from '../../../theme/styles';
import background from '../../../assets/background.jpg';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover'
    },
    content: {
      minHeight: 'calc(100vh - 188px)'
      //paddingTop: 68 // Header height
    }
  })
);

const MainLayout = ({ loading, children }) => {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  const isDesktop = IsDesktopScreenSize();
  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? false : openSidebar;

  return (
    <Box className={classes.root}>
      <Topbar hasMenu={true} onSidebarOpen={handleSidebarOpen} />

      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />

      <Box className={classes.content}>
        <Box className={`${layoutClasses.marginHorizontal} ${layoutClasses.marginVertical}`}>
          <Container
            maxWidth={false}
          >
            <Grid container justify="center" alignItems="center">
              {loading ? <FullPageSpinner /> : children}
            </Grid>
          </Container>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool
};

export default MainLayout;
