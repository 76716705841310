import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  directLineToken: /* localStorage.getItem('directLineToken') */ null,
  isFetchingDirectLineToken: false,
  fetchDirectLineTokenError: null,

  subscriberStatus: null,
  isFetchingSubscriberStatus: false,
  fetchSubscriberStatusError: null
};

const webchatSlice = createSlice({
  name: 'webchat',
  initialState,
  reducers: {
    directLineTokenFetchStart: state => ({
      ...state,
      isFetchingDirectLineToken: true,
      fetchDirectLineTokenError: null
    }),

    directLineTokenFetchFail: (state, action) => ({
      ...state,
      isFetchingDirectLineToken: false,
      fetchDirectLineTokenError: action.payload
    }),

    directLineTokenFetchSuccess: (state, action) => ({
      ...state,
      isFetchingDirectLineToken: false,
      fetchDirectLineTokenError: null,
      directLineToken: action.payload
    }),

    subscriberStatusFetchStart: state => ({
      ...state,
      isFetchingSubscriberStatus: true,
      fetchSubscriberStatusError: null
    }),

    subscriberStatusFetchFail: (state, action) => ({
      ...state,
      isFetchingSubscriberStatus: false,
      fetchSubscriberStatusError: action.payload
    }),

    subscriberStatusFetchSuccess: (state, action) => ({
      ...state,
      isFetchingSubscriberStatus: false,
      fetchSubscriberStatusError: null,
      subscriberStatus: action.payload
    }),

    reset: () => {
      return {
        ...initialState
      };
    }
  }
});

export const webchatActions = webchatSlice.actions;
export default webchatSlice.reducer;
